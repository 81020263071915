import React from 'react';
import { useSelector } from 'react-redux';
import { Container, Row } from 'reactstrap';
import { AutobizIcon, AutobizSectionTitle } from 'autobiz-strap';
import { t } from 'autobiz-translate';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import Footer from '../Footer/Footer';
import Header from '../Header/Header';

import ProductCard from '../ProductCard/ProductCard';
import { allProducts } from '../../../../hexagon/config/appConfig';
import { ProductName, Products } from '../../../../hexagon/models/productModel';
import UserFeeds from '../UserFeeds/UserFeeds';
import { getUserFeedsSelector } from '../../view-models-generators/userFeedsSelectors';

import UserStats from '../UserStats/UserStats';
import { getUserSelector } from '../../view-models-generators/userSelector';
import { getLinkLanguage } from '../../../../language-context';
import PopUpWebinar from '../PopupWebinar/PopupWebinar';
// import PopUpNps from '../PopupNps/PopupNps';
import AuthSpinner from '../AuthSpinner/AuthSpinner';
import ZohoSurvey from '../ZohoSurvey/ZohoSurvey';

const difference = (arr1: ProductName[], arr2: ProductName[]) =>
    arr1.filter((x: ProductName) => !arr2.includes(x));

const Dashboard: React.FC = () => {
    const { count: actionCount, data } = useSelector(getUserFeedsSelector);
    const { user } = useSelector(getUserSelector);
    const userProducts = user?.userApps ? user?.userApps.map((obj: any) => obj.name) : [];

    const productsWithoutOffice = user?.userApps
        ? user?.userApps.filter((p: any) => p.name !== 'autobizOffice')
        : [];

    const discoverableProducts = [] as Products;

    difference(allProducts, userProducts).forEach((p) => {
        discoverableProducts.push({
            name: p,
            link: `https://office.autobiz.com/${getLinkLanguage(user.language)}/demo/`,
        });
    });

    return (
        <>
            <Header />
            <Container className="hasFixedHeader" />

            <PopUpWebinar {...user} />
            {/* <PopUpNps {...user} /> */}
            <ZohoSurvey user={user} />

            <Container>
                {user.lastName && (
                    <div className="lead mt-5">
                        {t('hello')} {user.firstName} {user.lastName}
                        {user.provider === 'AZ' && (
                            <>
                                {Object.keys(data).length > 0 && (
                                    <>
                                        , {t('you_have')}{' '}
                                        <span className="text-primary font-weight-bold">
                                            {actionCount}
                                        </span>{' '}
                                        {t('action_to_perform')}
                                    </>
                                )}{' '}
                            </>
                        )}
                    </div>
                )}

                <UserFeeds />
                <UserStats />

                {productsWithoutOffice.length > 0 && (
                    <div className="mt-4">
                        <AutobizSectionTitle>
                            <AutobizIcon name="Grid" /> {t('your_subscribed_products')}
                        </AutobizSectionTitle>
                        <Row>
                            {productsWithoutOffice.map((product: any) => (
                                <ProductCard key={product.name} product={product} type="connect" />
                            ))}
                        </Row>
                    </div>
                )}
                {user.provider === 'AZ' && (
                    <div className="mt-5">
                        <AutobizSectionTitle>
                            <AutobizIcon name="Cloud" /> {t('discover_other_products')}
                        </AutobizSectionTitle>
                        <Row>
                            {discoverableProducts.map((product) => (
                                <ProductCard key={product.name} product={product} type="demo" />
                            ))}
                        </Row>
                    </div>
                )}
                <Footer />
            </Container>
        </>
    );
};

export default withAuthenticationRequired(Dashboard, {
    onRedirecting: () => <AuthSpinner />,
});
