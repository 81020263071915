import { left, right } from 'fp-ts/Either';
import axios from 'axios';
import { ApiResponse } from '../../../../hexagon/infra/ApiResponse';
import { BaseApi } from '../../../../hexagon/infra/BaseApi';
import { UserInfoGateway } from '../../../../hexagon/gateways/userInfoGateway.interface';
import { UpdatableUserInfo, User } from '../../../../hexagon/models/userModel';
import { ACCOUNTS_URL_BASE, API_CARCHECK_URL, API_USER_URL } from '../../../../config';
import { UserInfoMapper } from './mappers/userInfo.mapper';
import { AutobizAccountsUserInfoDto } from './dtos/autobizAccountsUserInfoDto';
import { PreferencesMapper } from './mappers/preferences.mapper';
import { Preferences } from '../../../../hexagon/models/preferencesModel';
import { OfficeLanguage } from '../../../../hexagon/config/appConfig';
import { getCanEditPrefrences } from './canEditPreferences';

export class AutobizAccountsUserInfoGateway extends BaseApi implements UserInfoGateway {
    setToken(token: string): ApiResponse<boolean> {
        if (token) {
            this.setBearerToken(token);
            return right(true);
        }

        return left({ code: '401', message: 'not authorized' });
    }

    async getCurrentUserInfo(): Promise<ApiResponse<User>> {
        try {
            const r = await this.get(`${ACCOUNTS_URL_BASE}/me`, {});
            const dto: AutobizAccountsUserInfoDto = r.data;

            const user = UserInfoMapper.toModel(dto, this.bearerToken || '');

            let canEditPreferences = false;
            if (dto.orgRoles.includes('isAccountAdmin')) {
                if (dto.orgRoles.includes('buyerCarcheck')) {
                    await axios
                        .get(
                            `${API_CARCHECK_URL}/carcheckusers/${dto.autobiz_id.replace('|', '')}`,
                            {
                                headers: {
                                    'Content-type': 'application/json',
                                    Authorization: `Bearer ${this.bearerToken}`,
                                },
                            },
                        )
                        .then((response) => {
                            let carcheckRoles: any = [];
                            carcheckRoles = response.data.role;
                            canEditPreferences = getCanEditPrefrences(dto.orgRoles, carcheckRoles);
                            if (canEditPreferences && dto.provider === 'SMART') {
                                canEditPreferences = false;
                            }
                        })
                        .catch(() => {
                            canEditPreferences = false;
                        });
                } else if (dto.provider !== 'SMART') {
                    canEditPreferences = true;
                }
            }

            user.canEditPreferences = canEditPreferences;

            return right(user);
        } catch (error) {
            return left({ code: 'get_users_info_failed', message: 'get users info failed' });
        }
    }

    async updateUserInfo(userInfo: Partial<UpdatableUserInfo>): Promise<ApiResponse<boolean>> {
        let data = {};
        if (userInfo.email) {
            data = { ...data, ...{ email: userInfo.email, email_verified: false } };
        }

        if (userInfo.phone) {
            data = { ...data, ...{ phone_number: userInfo.phone, phone_verified: false } };
        }

        try {
            const response = await this.put(
                `${ACCOUNTS_URL_BASE}/users/${userInfo.id}/onboarding`,
                {
                    data,
                },
            );

            if (response.status === 200) {
                return right(true);
            }
            return left({ code: 'error_code', message: 'error_message' });
        } catch {
            return left({ code: 'error_code', message: 'error_message' });
        }
    }

    async getDefaultPreferences(country: string): Promise<ApiResponse<Preferences>> {
        try {
            const response = await this.get(`${API_USER_URL}/preferences/defaults/${country}`);
            return right(PreferencesMapper.toModel(response.data));
        } catch (error) {
            return left({ code: 'error_code', message: 'error_message' });
        }
    }

    async getPreferences(
        subscriptionId: number,
        country: string,
    ): Promise<ApiResponse<Preferences>> {
        try {
            const response = await this.get(
                `${API_USER_URL}/preferences/subscription/${subscriptionId}/country/${country}`,
            );

            return right(PreferencesMapper.toModel(response.data));
        } catch (error) {
            return left({ code: 'error_code', message: 'error_message' });
        }
    }

    async updateLang(userId: string, langId: string): Promise<ApiResponse<OfficeLanguage>> {
        try {
            const response = await this.put(`${ACCOUNTS_URL_BASE}/users/${userId}`, {
                data: {
                    preferred_language: `/api/languages/${langId}`,
                },
            });

            if (response.status === 200) {
                // const lang = officeLanguages.find((l) => l.id === langId);
                // return await Promise.resolve(right(lang?.language_iso2 as OfficeLanguage));
                const { language_iso2: language } = response.data.preferred_language;
                return right(language as OfficeLanguage);
            }

            return left({ code: 'error_code', message: 'error_message' });
        } catch (error) {
            return left({ code: 'error_code', message: 'error_message' });
        }
    }

    async updatePreferences(
        subscriptionId: number,
        country: string,
        preferences: Preferences,
    ): Promise<ApiResponse<Preferences>> {
        try {
            const response = await this.put(`${API_USER_URL}/preferences`, {
                data: {
                    country,
                    subscriptionId,
                    ...PreferencesMapper.toDto(preferences),
                },
            });

            if (response.status === 204) {
                const result = await this.getPreferences(subscriptionId, country);
                return result;
            }
            return left({ code: 'error_code', message: 'error_message' });
        } catch (error) {
            return left({ code: 'error_code', message: 'error_message' });
        }
    }
}
